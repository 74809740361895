import img from "../assets/rocket.png";
import bg from "../assets/Takeoff.png";
import video from "../assets/video.mp4";
// takeoff-video
const Section = () => {
  return (
    <div style={{ overflow: "hidden" }}>
      <div style={{ position: "relative" }}>
        <video
          id="background-video"
          className="takeoffVideo"
          playsInline
          autoPlay
          loop
          muted
        >
          <source src={video} type="video/mp4" />
          <source src={video} type="video/ogg" />
        </video>
      </div>
      <div className="section tkoff" style={{ background: `url(${bg})` }}>
        <div className="section-container full-width">
          <div className="section-text">
            <h3 className="roadmap-number">5.</h3>

            <div
              className="subtitle subtitle-center"
              style={{ color: "#bebebe" }}
            >
              The show must go on
            </div>
            <h2 className="cta-title section-tile section-title-center">
              VIP Events
            </h2>
            <p className="cta-text">
              Apes in Space will organize the biggest Hip-Hop festival in the
              Metaverse, with the main guest being Takeoff, who will be
              performing alongside other renowned artists in the industry. All
              Apes in Space holders will be able to attend the event for free.
            </p>
            {/* Four VIP concert tickets for a future Migos concert will be
              awarded to 2 lucky NFT holders. */}
            {/* <div className="discord-btn-container">
              <a
                className="discord-btn"
                onClick={() =>
                  window.open(
                    "https://discord.com/invite/apesinspace",
                    "_blank"
                  )
                }
              >
                Join Discord
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section;
