import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import ape1 from "../assets/1.png";
import ape2 from "../assets/2.png";
import ape3 from "../assets/3.png";
import ape4 from "../assets/4.png";
import ape5 from "../assets/5.png";
import ape6 from "../assets/6.png";

import $ from "jquery";

export default function SimpleSlider() {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);
  const sliderRef = useRef();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  function addClass() {
    $(".slick-active .c-slide_image").each(function (i, e) {
      if (i % 2 !== 0) return;
      $(e).addClass("c-move-to-bottom");
    });
  }

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    afterChange: () => addClass(),
    beforeChange: () => $(".c-move-to-bottom").removeClass("c-move-to-bottom"),
    autoplay: true,
    autoplaySpeed: 3500,
    arrows: false,
    centerMode: true,
    pauseOnHover: false,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div data-aos="zoom-out-up">
      <div className="vertical vertical-1">
        <h2
          style={{
            transform: `translateX(${offsetY * 0.4}px)`,
          }}
        >
          Ready for
          <br />
          Takeoff
        </h2>
      </div>

      <div className="vertical vertical-2">
        <h2
          style={{
            transform: `translateX(${offsetY * 0.4}px)`,
          }}
        >
          Ready for
          <br />
          Takeoff
        </h2>
      </div>
      <Slider
        ref={sliderRef}
        {...settings}
        // autoplay="true"
        // autoplaySpeed="5000"
        // speed="500"
        // variableWidth="true"
      >
        <div
          className="c-slide_wrapper"
          // style={{ backgroundImage: `url(${ape1})` }}
        >
          <img
            src={ape1}
            className="slider-img c-slide_image c-move-to-bottom"
            alt="slider"
          />
        </div>
        <div className="c-slide_wrapper">
          <img src={ape2} className="slider-img c-slide_image" alt="slider" />
        </div>
        <div className="c-slide_wrapper">
          <img
            src={ape3}
            className="slider-img c-slide_image c-move-to-bottom"
            alt="slider"
          />
        </div>
        <div className="c-slide_wrapper">
          <img src={ape4} className="slider-img c-slide_image" alt="slider" />
        </div>
        <div className="c-slide_wrapper">
          <img
            src={ape5}
            className="slider-img c-slide_image c-move-to-bottom"
            alt="slider"
          />
        </div>
        <div className="c-slide_wrapper">
          <img src={ape6} className="slider-img c-slide_image" alt="slider" />
        </div>
      </Slider>

      {/* <div class="c-small-text">
        <div class="c-title-2">
          READY FOR
          <br />
          TAKEOFF
        </div>
        <div class="c-text-line"></div>
      </div> */}

      <div className="c-arrows-wrapper">
        <div
          id="arrow-prev"
          className="c-arrow slick-arrow"
          onClick={() => sliderRef.current.slickPrev()}
        >
          <div className="c-arrow_embed w-embed">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.33317 8.13338L12.6665 8.13338"
                stroke="currentColor"
                strokeLinecap="square"
                strokeLinejoin="round"
              ></path>
              <path
                d="M6.51342 12.5535L6.86773 12.9063L7.57332 12.1976L7.219 11.8449L6.51342 12.5535ZM2.83288 8.18317L2.48006 7.82889L2.12427 8.1832L2.48009 8.53749L2.83288 8.18317ZM7.21903 4.52079L7.57332 4.16797L6.86767 3.4594L6.51339 3.81222L7.21903 4.52079ZM7.219 11.8449L3.18567 7.82886L2.48009 8.53749L6.51342 12.5535L7.219 11.8449ZM3.1857 8.53746L7.21903 4.52079L6.51339 3.81222L2.48006 7.82889L3.1857 8.53746Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
        <div
          id="arrow-next"
          className="c-arrow cc-right slick-arrow"
          onClick={() => sliderRef.current.slickNext()}
        >
          <div className="c-arrow_embed w-embed">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.33317 8.13338L12.6665 8.13338"
                stroke="currentColor"
                strokeLinecap="square"
                strokeLinejoin="round"
              ></path>
              <path
                d="M6.51342 12.5535L6.86773 12.9063L7.57332 12.1976L7.219 11.8449L6.51342 12.5535ZM2.83288 8.18317L2.48006 7.82889L2.12427 8.1832L2.48009 8.53749L2.83288 8.18317ZM7.21903 4.52079L7.57332 4.16797L6.86767 3.4594L6.51339 3.81222L7.21903 4.52079ZM7.219 11.8449L3.18567 7.82886L2.48009 8.53749L6.51342 12.5535L7.219 11.8449ZM3.1857 8.53746L7.21903 4.52079L6.51339 3.81222L2.48006 7.82889L3.1857 8.53746Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}
