import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@material-ui/core";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Popup = (props) => {
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const [unclaimed, setUnclaimed] = useState();
  const [isClaiming, setIsClaiming] = useState(false);

  useEffect(() => {
    if (props.modal.token.edition && props.account) {
      props.rewardContract.methods
        .rewardBalance(props.modal.token.edition)
        .call()
        .then((balance) => {
          setUnclaimed(balance);
        });
    }
  }, [props.modal.token]);

  const claim = () => {
    setIsClaiming(true);

    props.rewardContract.methods
      .claim(props.modal.token.edition)
      .send({ from: props.account })
      .once("receipt", (receipt) => {
        props.setAlertState({
          open: true,
          message: "Successfully claimed Takeoff",
          severity: "success",
        });
        setIsClaiming(false);
      })
      .catch((e) => {
        setIsClaiming(false);
      });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="token-popup"
      open={props.modal.open}
      onClose={() => props.setModal({ open: false, token: {} })}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.modal.open}>
        <Box sx={style}>
          <h3 id="transition-modal-title" variant="h6" component="h2">
            {props.modal.token?.name}
          </h3>
          <div>
            <p>
              {(unclaimed / 1000000000000000000).toFixed(2)} Unclaimed $TKOFF
            </p>
            <div
              className="discord-btn-container"
              style={{
                textAlign: "center",
                color: "black",
                marginTop: "20px",
              }}
            >
              {isClaiming ? (
                <div className="claiming-loading-container">
                  <CircularProgress />
                </div>
              ) : (
                <a
                  className="discord-btn"
                  onClick={() => claim()}
                  disabled={isClaiming}
                >
                  Claim
                </a>
              )}
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default Popup;
