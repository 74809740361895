import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import activity1 from "../assets/activity1.svg";
import activity2 from "../assets/activity2.svg";
import activity3 from "../assets/activity3.svg";
import activity4 from "../assets/activity4.svg";

const Activities = () => {
  return (
    <Container className="container">
      <h2 data-aos="zoom-out-up">Giveaways</h2>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          className="activity-item-container"
          data-aos="zoom-out-up"
        >
          <img className="activity-img" src={activity3} alt="acitivity-img" />
          <div className="activities-desc">
            <h3>the show must go on</h3>
            <p>
              Four VIP concert tickets for a future Migos concert will be
              awarded to 2 lucky NFT holders.
            </p>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          className="activity-item-container"
          data-aos="zoom-out-up"
        >
          <img className="activity-img" src={activity1} alt="acitivity-img" />
          <div className="activities-desc">
            <h3>High in space</h3>
            <p>
              One lucky Apes in Space holder will join Takeoff on a real-life
              space journey.
            </p>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          className="activity-item-container"
          data-aos="zoom-out-up"
        >
          <img className="activity-img" src={activity2} alt="acitivity-img" />
          <div className="activities-desc">
            <h3>NFTs</h3>
            <p>
              10 NFTs will be raffled away to the top inviters of our Discord
              server.
            </p>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          className="activity-item-container"
          data-aos="zoom-out-up"
        >
          <img className="activity-img" src={activity4} alt="acitivity-img" />
          <div className="activities-desc">
            <h3>mooning</h3>
            <p>
              Once the project reaches 500 ETH in secondary sales, we will be
              giving away $25,000 in ETH to one lucky Apes in Space holder.
            </p>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Activities;

// import * as React from "react";
// import Timeline from "@material-ui/lab/Timeline";
// import TimelineItem from "@material-ui/lab/TimelineItem";
// import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
// import TimelineConnector from "@material-ui/lab/TimelineConnector";
// import TimelineContent from "@material-ui/lab/TimelineContent";
// import TimelineDot from "@material-ui/lab/TimelineDot";
// import img1 from "../assets/rocket.png";

// export default function Giveaway() {
//   return (
//     <div>
//       <h2>Giveaways</h2>
//       <Timeline position="left">
//         <TimelineItem>
//           <TimelineSeparator>
//             <TimelineDot />
//             <TimelineConnector />
//           </TimelineSeparator>
//           <TimelineContent>
//             <div className="timeline-container">
//               <div className="timeline-section">
//                 <h3 className="timeline-number">1</h3>
//                 <h3>Eat</h3>
//                 <p>
//                   Once the project reaches 500 ETH in secondary sales, we will
//                   be giving away $25,000 in ETH to one lucky Apes in Space
//                   holder.
//                 </p>
//               </div>
//               <div className="timeline-section">
//                 <img src={img1} />
//               </div>
//             </div>
//           </TimelineContent>
//         </TimelineItem>
//         <TimelineItem>
//           <TimelineSeparator>
//             <TimelineDot />
//             <TimelineConnector />
//           </TimelineSeparator>
//           <TimelineContent>
//             <div className="timeline-container">
//               <div className="timeline-section">
//                 <h3 className="timeline-number">2</h3>
//                 <h3>VIP Tickets</h3>
//                 <p>
//                   Once the project reaches 500 ETH in secondary sales, we will
//                   be giving away $25,000 in ETH to one lucky Apes in Space
//                   holder.
//                 </p>
//               </div>
//               <div className="timeline-section">
//                 <img src={img1} />
//               </div>
//             </div>
//           </TimelineContent>
//         </TimelineItem>
//         <TimelineItem>
//           <TimelineSeparator>
//             <TimelineDot />
//           </TimelineSeparator>
//           <TimelineContent>
//             <div className="timeline-container">
//               <div className="timeline-section">
//                 <h3 className="timeline-number">3</h3>

//                 <h3>To Space</h3>
//                 <p>
//                   Once the project reaches 500 ETH in secondary sales, we will
//                   be giving away $25,000 in ETH to one lucky Apes in Space
//                   holder.
//                 </p>
//               </div>
//               <div className="timeline-section">
//                 <img src={img1} />
//               </div>
//             </div>
//           </TimelineContent>
//         </TimelineItem>
//       </Timeline>
//     </div>
//   );
// }
