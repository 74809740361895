import img from "../assets/rocket.png";
import bg from "../assets/1009.png";

const Section = () => {
  return (
    <div style={{ background: `url(${bg})`, backgroundPosition: "center" }}>
      <div
        className="section gradient"
        //       style={{
        //         background: `linear-gradient(
        //   0deg,
        //   rgba(0, 0, 0, 1) 0%,
        //   rgb(0, 0, 0, 0.12) 50%,
        //   rgba(0, 0, 0, 1) 100%
        // )`,
        //       }}
      >
        <div
          className="section-container section-container-img"
          data-aos="fade-up"
          data-aos-offset="250"
        >
          <img src={img} />
        </div>
        <div
          className="section-container"
          data-aos="fade-up"
          data-aos-offset="250"
        >
          <div className="section-text">
            <h3 className="roadmap-number">4.</h3>

            <div className="subtitle">High in space</div>
            <h2 className="cta-title section-tile">Takeoff</h2>
            <p className="cta-text">
              One lucky Apes in Space holder will join Takeoff on a real-life
              space journey. The lucky owner will get to meet the Migos rapper
              in person and become the first person to orbit in space with one
              of the most talented artist in the space.
            </p>
            <div className="discord-btn-container">
              <a
                className="discord-btn"
                onClick={() =>
                  window.open(
                    "https://discord.com/invite/apesinspace",
                    "_blank"
                  )
                }
              >
                Join Discord
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section;
