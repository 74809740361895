import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";

const CTA = () => {
  return (
    <Container className="container" id="about">
      <div className="subtitle" data-aos="zoom-out-up">
        Discord
      </div>
      <h2 data-aos="zoom-out-up" style={{ marginTop: "0px" }}>
        whitelist
      </h2>

      <p className="about-text" data-aos="zoom-out-up">
        20% of the supply (2,000 NFTs) will be made available to qualified
        Whitelist members at a special discount.
      </p>

      <p
        className="about-text"
        data-aos="zoom-out-up"
        style={{ marginBottom: "50px" }}
      >
        Apes in Space aims to become the fastest-growing community in the crypto
        space, with every connection in the NFT environment promoting the
        project. Join our Discord today to reserve your seat on your trip to the
        Moon!
      </p>

      <div
        className="discord-btn-container"
        style={{ textAlign: "center" }}
        data-aos="zoom-out-up"
      >
        <a
          className="discord-btn"
          onClick={() =>
            window.open("https://discord.com/invite/apesinspace", "_blank")
          }
        >
          Join Discord
        </a>
      </div>
    </Container>
  );
};

export default CTA;
