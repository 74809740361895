import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Footer from "../components/Footer";
import Tokens from "../components/profile/tokens";
import Merch from "../components/profile/merch";
import Informations from "../components/profile/informations";
import MyOrders from "../components/profile/orders";
import Alert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";
import { useParams } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Profile = (props) => {
  const [value, setValue] = React.useState(0);
  const { tab } = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (tab == "my-apes") {
      setValue(0);
    } else if (tab == "merch") {
      setValue(1);
    } else if (tab == "informations") {
      setValue(2);
    } else if (tab == "orders") {
      setValue(3);
    }
  }, []);

  const Content = () => {
    return (
      <>
        <Container className="container" style={{ marginTop: "40px" }}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={value} onChange={handleChange} aria-label="tabs">
                <Tab label="My Apes" {...a11yProps(0)} />
                <Tab label="Merch" {...a11yProps(1)} />
                <Tab label="Profile" {...a11yProps(2)} />
                <Tab label="Orders" {...a11yProps(3)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0} data-aos="zoom-out-up">
              <Tokens
                rewardContract={props.rewardContract}
                metamask={props.metamask}
                isWalletConnected={props.isWalletConnected}
                alertState={props.alertState}
                setAlertState={props.setAlertState}
                account={props.account}
                contract={props.contract}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Merch rewardContract={props.rewardContract} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Informations
                // setAlertState={props.setAlertState}
                account={props.account}
              />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <MyOrders
                // setAlertState={props.setAlertState}
                account={props.account}
              />
            </TabPanel>
          </Box>
        </Container>

        <Snackbar
          open={props.alertState.open}
          autoHideDuration={6000}
          onClose={() =>
            props.setAlertState({ ...props.alertState, open: false })
          }
          className="alert"
        >
          <Alert
            onClose={() =>
              props.setAlertState({ ...props.alertState, open: false })
            }
            severity={props.alertState.severity}
            className="alert"
          >
            <h3 style={{ fontSize: "15px" }}>{props.alertState.message}</h3>
          </Alert>
        </Snackbar>
      </>
    );
  };

  return (
    <>
      {props.metamask && props.isWalletConnected ? (
        <Content />
      ) : (
        <Container
          className="container container-margin-top"
          style={{ minHeight: "50vh" }}
        >
          <h2 style={{ textAlign: "center" }}>Profile</h2>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ textAlign: "center" }}>
              {!props.metamask ? (
                <div
                  className="discord-btn"
                  onClick={() =>
                    window.open(
                      "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn",
                      "_blank"
                    )
                  }
                >
                  Install Metamask
                </div>
              ) : !props.isWalletConnected ? (
                <div
                  className="discord-btn"
                  onClick={() => window.ethereum.enable()}
                >
                  Connect Wallet
                </div>
              ) : (
                <Content />
              )}
            </div>
          </div>
        </Container>
      )}

      <Footer />
    </>
  );
};

export default Profile;
