import img from "../assets/stacking.png";
import bg from "../assets/1015.png";

const Section = () => {
  return (
    <div style={{ background: `url(${bg})`, backgroundPosition: "center" }}>
      <div className="section gradient reverse">
        <div
          className="section-container"
          data-aos="fade-up"
          data-aos-offset="250"
        >
          <div className="section-text">
            <h3 className="roadmap-number">1.</h3>

            <div className="subtitle">Rewards</div>
            <h2 className="cta-title section-tile">Staking</h2>
            <p className="cta-text">
              The Apes in Space holders will be able to stake their NFTs in
              exchange for a native token <b>$TKOFF</b> unique to the project,
              which will be used for a number of future utilities. These
              utilities will only be accessible through the use of the native
              tokens.
            </p>
            <div className="discord-btn-container">
              <a
                className="discord-btn"
                onClick={() =>
                  window.open(
                    "https://discord.com/invite/apesinspace",
                    "_blank"
                  )
                }
              >
                Join Discord
              </a>
            </div>
          </div>
        </div>
        <div
          className="section-container section-container-img"
          data-aos="fade-up"
          data-aos-offset="250"
        >
          <img src={img} />
        </div>
      </div>
    </div>
  );
};

export default Section;
