import React, { useState, useEffect } from "react";
import background from "../assets/bg.mp4";
import apeInSpace from "../assets/astronaut.png";

import Discord from "../assets/discord.svg";
import Instagram from "../assets/instagram.svg";
import Twitter from "../assets/twitter.svg";
import Opensea from "../assets/opensea.svg";
import LogoText from "../assets/logo-text.png";
import Countdown from "react-countdown";
import MintButton from "./MintButton";
import $ from "jquery";

const Hero = (props) => {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (hours < 10) {
      hours = "0" + hours;
    }

    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    return (
      <div className="countdown-value" style={{ fontWeight: "bold" }}>
        {days} Days {hours}:{minutes}:{seconds}
      </div>
    );
  };

  return (
    <div>
      <div
        className="hero-container"
        data-aos="fade-in"
        //   style={{
        //     backgroundImage: `url(${background})`,
        //   }}
      >
        <video id="background-video" playsInline autoPlay loop muted>
          <source src={background} type="video/mp4" />
          <source src={background} type="video/ogg" />
        </video>
        <div>
          {/* <h1 className="hero-text">
            Apes In <br /> <span>Space</span>
          </h1> */}
          <img src={LogoText} className="hero-text" />
        </div>

        {/* <div
          style={{
            transform: `rotate(${(offsetY * 0.5) / 10}deg)`,
          }}
        > */}
        {$(window).width() > 960 ? (
          <div className="floating">
            <img
              src={apeInSpace}
              className="ape-in-space"
              style={{
                transform: `translate(-65%, 20%) rotate(${
                  (offsetY * 0.5) / 10
                }deg)`,
                width: `${600 - offsetY * 0.5}px`,
              }}
              alt="apesinspace"
            />
          </div>
        ) : (
          <img
            src={apeInSpace}
            className="ape-in-space"
            style={{
              transform: `translate(-65%, 20%) rotate(${
                (offsetY * 0.5) / 10
              }deg)`,
              width: `${600 - offsetY * 0.5}px`,
            }}
            alt="apesinspace"
          />
        )}

        {/* </div> */}

        <div className="hero-socials" style={{ marginTop: "0px" }}>
          <div className="footer-social">
            <a
              style={{ WebkitMask: `url(${Discord}) no-repeat center` }}
              onClick={() =>
                window.open("https://discord.com/invite/apesinspace", "_blank")
              }
            />
          </div>
          <div className="footer-social">
            <a
              style={{ WebkitMask: `url(${Twitter}) no-repeat center` }}
              onClick={() =>
                window.open("https://twitter.com/ApesinSpaceNFT", "_blank")
              }
            />
          </div>
          <div className="footer-social">
            <a
              style={{ WebkitMask: `url(${Instagram}) no-repeat center` }}
              onClick={() =>
                window.open(
                  "https://www.instagram.com/apesinspace.io/",
                  "_blank"
                )
              }
              className="instagram"
            />
          </div>
          <div className="footer-social">
            <img
              src={Opensea}
              className="opensea"
              onClick={() =>
                window.open(
                  "https://opensea.io/collection/apes-in-space-nft",
                  "_blank"
                )
              }
            />
          </div>
        </div>

        <div
          className="discord-btn-hero-container"
          style={{ position: "absolute" }}
        >
          <a
            className="discord-btn-hero"
            onClick={() =>
              window.open(
                "https://opensea.io/collection/apes-in-space-nft",
                "_blank"
              )
            }
          >
            Buy on OpenSea
          </a>
        </div>
        {/* <MintButton
          metamask={props.metamask}
          isWalletConnected={props.isWalletConnected}
          mint={props.mint}
          startDate={props.startDate}
          isActive={props.isActive}
          setActive={props.setActive}
          setCount={props.setCount}
          count={props.count}
          isPaused={props.isPaused}
          isSoldOut={props.isSoldOut}
          isWhitelisted={props.isWhitelisted}
          exceedLimit={props.exceedLimit}
          isPresale={props.isPresale}
          isPublic={props.isPublic}
          alertState={props.alertState}
          setAlertState={props.setAlertState}
          isMinting={props.isMinting}
        /> */}
      </div>
      <div className="hero-bar">
        {/* <div className="bar-column left">
          <div className="bar-title">Supply</div>
          <div className="bar-desc">9,999</div>
        </div> */}
        <div className="bar-column left">
          <div className="bar-title">PreSale</div>
          <div className="bar-desc">December 5th, 4PM EST</div>
          <Countdown date={props.startDate} renderer={renderer} />
        </div>

        <div className="bar-column">
          <div className="bar-title">Public Sale</div>
          <div className="bar-desc">December 6th, 9PM EST</div>
          <Countdown date={props.publicStartDate} renderer={renderer} />
        </div>
      </div>
    </div>
  );
};

export default Hero;
