import { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Logo from "../assets/logo.png";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import { Spin as Hamburger } from "hamburger-react";
import Discord from "../assets/discord.svg";
import Instagram from "../assets/instagram.svg";
import Twitter from "../assets/twitter.svg";
import Opensea from "../assets/opensea.svg";
import Profile from "../assets/profile.svg";

const Navbar = (props) => {
  const [menuToggle, setMenuToggle] = useState(false);

  const list = () => (
    <Box
      onClick={() => setMenuToggle(false)}
      onKeyDown={() => setMenuToggle(false)}
    >
      <List>
        {/* <ListItem className="drawer-item">
          <h3>
            <a href="#about">About</a>
          </h3>
        </ListItem> */}
        <ListItem className="drawer-item">
          <h3>
            <a href="#roadmap">Roadmap</a>
          </h3>
        </ListItem>
        <ListItem className="drawer-item">
          <h3>
            <a href="#team">Team</a>
          </h3>
        </ListItem>
        <ListItem className="drawer-item">
          <h3>
            <a href="#faq">FAQ</a>
          </h3>
        </ListItem>
        {/* <ListItem className="drawer-item">
          <h3 onClick={() => window.location.replace("/whitelist")}>
            whitelist
          </h3>
        </ListItem> */}
        <ListItem className="drawer-item">
          <h3 onClick={() => window.location.replace("/how-to-mint")}>
            How to mint
          </h3>
        </ListItem>
      </List>
      <Divider />
      <div className="footer-socials" style={{ marginTop: "30px" }}>
        <a
          style={{ WebkitMask: `url(${Discord}) no-repeat center` }}
          onClick={() =>
            window.open("https://discord.com/invite/apesinspace", "_blank")
          }
        />
        <a
          style={{ WebkitMask: `url(${Twitter}) no-repeat center` }}
          onClick={() =>
            window.open("https://twitter.com/ApesinSpaceNFT", "_blank")
          }
        />
        <a
          style={{ WebkitMask: `url(${Instagram}) no-repeat center` }}
          onClick={() =>
            window.open("https://www.instagram.com/apesinspace.io/", "_blank")
          }
          className="instagram"
        />
        <img
          src={Opensea}
          style={{ height: "39px" }}
          onClick={() =>
            window.open(
              "https://opensea.io/collection/apes-in-space-nft",
              "_blank"
            )
          }
        />
      </div>
    </Box>
  );

  return (
    <div className="navbar">
      <Drawer
        anchor="right"
        open={menuToggle}
        onClose={() => setMenuToggle(false)}
        PaperProps={{ className: "drawer" }}
      >
        {list()}
      </Drawer>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          style={{ background: "transparent", boxShadow: "none" }}
        >
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <IconButton
                size="medium"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => window.location.replace("/")}
              >
                <img src={Logo} className="logo" alt="logo" />
                {/* <a className="logo-text">Apes In Space</a> */}
              </IconButton>
            </Typography>

            <div className="nav-socials" style={{ marginTop: "0px" }}>
              <div className="footer-social">
                <a
                  style={{ WebkitMask: `url(${Discord}) no-repeat center` }}
                  onClick={() =>
                    window.open(
                      "https://discord.com/invite/apesinspace",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className="footer-social">
                <a
                  style={{ WebkitMask: `url(${Twitter}) no-repeat center` }}
                  onClick={() =>
                    window.open("https://twitter.com/ApesinSpaceNFT", "_blank")
                  }
                />
              </div>
              <div className="footer-social">
                <a
                  style={{
                    WebkitMask: `url(${Instagram}) no-repeat center`,
                    height: "22px",
                  }}
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/apesinspace.io/",
                      "blank"
                    )
                  }
                />
              </div>
            </div>

            {/* <div className="minter-navbar" style={{ marginRight: "50px" }}>
              <div
                className="discord-btn"
                onClick={() =>
                  window.open("https://discord.com/invite/GWAUw7YfaG", "_blank")
                }
              >
                Join Discord
              </div>
            </div> */}
            <IconButton
              size="medium"
              edge="start"
              color="inherit"
              aria-label="menu"
            >
              <img
                src={Profile}
                className="profile"
                onClick={() => window.location.replace("/profile")}
              />
            </IconButton>
            <IconButton
              size="medium"
              edge="start"
              color="inherit"
              aria-label="menu"
              style={{ marginLeft: 0 }}
            >
              <Hamburger
                toggled={menuToggle}
                onToggle={(toggled) => setMenuToggle(!menuToggle)}
              />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
};

export default Navbar;
