/* eslint-disable no-undef */
import { useEffect } from "react";
import Container from "@mui/material/Container";
import Logo from "../assets/logo.png";

const Loader = () => {
  useEffect(() => {
    const tween3 = new TimelineMax();

    const loaderBackground = document.querySelectorAll(".loader");

    tween3
      .add(
        TweenMax.to(loaderBackground, 1, {
          opacity: 0,
          delay: 1.25,
          duration: 2,
        })
      )
      .add(
        TweenMax.to(loaderBackground, 0.1, {
          height: 0,
          y: "-200vh",
          display: "none",
        })
      );
  }, []);

  return (
    <Container className="container loader">
      <div data-aos="fade-in">
        {/* <div>
          <img src={Logo} className="logo" alt="logo" />
        </div> */}
        <div>
          <img src={Logo} className="loader-logo" />
        </div>
      </div>
    </Container>
  );
};

export default Loader;
