import * as React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Discord from "../assets/discord.svg";
import Instagram from "../assets/instagram.svg";
import Twitter from "../assets/twitter.svg";
import Opensea from "../assets/opensea.svg";
import LogoText from "../assets/logo-text.png";

export default function Footer() {
  return (
    <Container className="container footer">
      <Box className="footer-box">
        <div>
          <div>
            <h3>Apes In Space</h3>
          </div>
          <p className="contract">
            <a
              onClick={() =>
                window.open(
                  "https://etherscan.io/address/0x7a3b97a7400e44dadd929431a3640e4fc47daebd#code",
                  "_blank"
                )
              }
            >
              AIS Contract
            </a>
          </p>
          <p className="rights">©2021 Apes In Space. All rights reserved.</p>
        </div>
        <div>
          <ul className="footer-links">
            <li onClick={() => window.location.replace("/")}>Home</li>
            <li onClick={() => window.location.replace("/terms")}>
              Terms & Conditions
            </li>
          </ul>
          <div className="footer-socials">
            <div className="footer-social">
              <a
                style={{ WebkitMask: `url(${Discord}) no-repeat center` }}
                onClick={() =>
                  window.open(
                    "https://discord.com/invite/apesinspace",
                    "_blank"
                  )
                }
              />
            </div>
            <div className="footer-social">
              <a
                style={{ WebkitMask: `url(${Twitter}) no-repeat center` }}
                onClick={() =>
                  window.open("https://twitter.com/ApesinSpaceNFT", "_blank")
                }
              />
            </div>
            <div className="footer-social">
              <a
                style={{
                  WebkitMask: `url(${Instagram}) no-repeat center`,
                  height: "22px",
                }}
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/apesinspace.io/",
                    "_blank"
                  )
                }
              />
            </div>
            <div className="footer-social">
              <a
                style={{
                  WebkitMask: `url(${Opensea}) no-repeat center`,
                  height: "22px",
                }}
                onClick={() =>
                  window.open(
                    "https://opensea.io/collection/apes-in-space-nft",
                    "_blank"
                  )
                }
              />
            </div>
          </div>
        </div>
      </Box>
    </Container>
  );
}
