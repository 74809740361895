import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import TextField from "@mui/material/TextField";

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: "" };
  }

  handleChange = (address) => {
    this.setState({ address });

    this.props.setZip(address);
  };

  handleSelect = (address) => {
    console.log(address);

    this.setState({ address });

    // geocodeByAddress(address)
    //   .then((results) => getLatLng(results[0]))
    //   .then((latLng) => console.log("Success", latLng))
    //   .catch((error) => console.error("Error", error));
  };

  render() {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <div style={{ display: "flex", width: "100%" }}>
              <TextField
                fullWidth
                id="demo-helper-text-aligned"
                label="Address"
                {...getInputProps({
                  placeholder: "Search Places ...",
                  className: "location-search-input informations-input",
                })}
              />
              {/* <input
              {...getInputProps({
                placeholder: "Search Places ...",
                className: "location-search-input",
              })}
            /> */}
            </div>
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#4b4b4b", cursor: "pointer" }
                  : { backgroundColor: "#000000", cursor: "pointer" };
                console.log(suggestion);
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default LocationSearchInput;
