import React, { useEffect, useState } from "react";
import Alert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";
import Hero from "../components/Hero";
import Activities from "../components/Activities";
import Slider from "../components/Slider";
import Team from "../components/Team";
import About from "../components/About";
import FAQ from "../components/FAQ";
import Section from "../components/Section";
import Section2 from "../components/Section2";
import Section3 from "../components/Section3";
import Section4 from "../components/Section4";
import Section5 from "../components/Section5";

import CTA from "../components/CTA";
import CTA2 from "../components/CTA2";
import Giveaway from "../components/Giveaways";

import Footer from "../components/Footer";
import "aos/dist/aos.css";

// import "aos/dist/aos.css";

const Home = (props) => {
  return (
    <main style={{ overflowX: "hidden", width: "100%" }}>
      <Hero
        startDate={props.startDate}
        publicStartDate={props.publicStartDate}
        metamask={props.metamask}
        isWalletConnected={props.isWalletConnected}
        mint={props.mint}
        isActive={props.isActive}
        setIsActive={props.setIsActive}
        setCount={props.setCount}
        count={props.count}
        isPaused={props.isPaused}
        isSoldOut={props.isSoldOut}
        isWhitelisted={props.isWhitelisted}
        exceedLimit={props.exceedLimit}
        isPresale={props.isPresale}
        isPublic={props.isPublic}
        alertState={props.alertState}
        setAlertState={props.setAlertState}
        isMinting={props.isMinting}
      />
      <About />
      <Slider />
      <CTA2 />

      <h2 id="roadmap">Roadmap</h2>
      <Section4 />
      <Section />
      <Section2 />
      <Section3 />
      <Section5 />
      {/* <Activities /> */}

      <Giveaway />

      <Team />
      <FAQ />
      <CTA />

      <Footer />

      <Snackbar
        open={props.alertState.open}
        autoHideDuration={6000}
        onClose={() =>
          props.setAlertState({ ...props.alertState, open: false })
        }
        className="alert"
      >
        <Alert
          onClose={() =>
            props.setAlertState({ ...props.alertState, open: false })
          }
          severity={props.alertState.severity}
          className="alert"
        >
          <h3 style={{ fontSize: "15px" }}>{props.alertState.message}</h3>
        </Alert>
      </Snackbar>
    </main>
  );
};

export default Home;
