import React, { Component, useRef } from "react";
import Slider from "react-slick";
import Container from "@mui/material/Container";
import team1 from "../assets/team1.jpg";
import team2 from "../assets/team2.jpg";
import team3 from "../assets/team3.jpg";
import team4 from "../assets/team4.jpg";
import team5 from "../assets/team5.jpg";
import team6 from "../assets/team6.jpg";

export default function Team() {
  const sliderRef = useRef();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const gotoNext = () => {
    sliderRef.current.slickNext();
  };
  return (
    <Container className="container" id="team">
      <h2 data-aos="zoom-out-up">Team</h2>
      <div className="flex-container" data-aos="zoom-out-up">
        <div className="flex-child">
          <Slider {...settings} ref={sliderRef}>
            {/* <div
              className="c-slide_wrapper"
              style={{ backgroundImage: `url(${ape})` }}
            >
              <img
                src={ape}
                className="slider-img c-slide_image c-move-to-bottom"
              />
            </div> */}
            <div>
              <img src={team4} className="team-img" />
              <p className="team-name">@Takeoff</p>
              <p className="team-role">Founder</p>
            </div>
            <div>
              <img src={team1} className="team-img" />
              <p className="team-name">@Boba Ape</p>
              <p className="team-role">Art Director</p>
            </div>
            <div>
              <img src={team2} className="team-img" />
              <p className="team-name">@Neil Apestrong</p>
              <p className="team-role">Communication</p>
            </div>
            <div>
              <img src={team3} className="team-img" />
              <p className="team-name">@Ape Windu</p>
              <p className="team-role">Developer</p>
            </div>
            <div>
              <img src={team5} className="team-img" />
              <p className="team-name">@Binto</p>
              <p className="team-role">Developer</p>
            </div>
            <div>
              <img src={team6} className="team-img" />
              <p className="team-name">@Blink</p>
              <p className="team-role">Artist</p>
            </div>
          </Slider>
          <div>
            <div className="c-arrows-wrapper-team">
              <div
                id="arrow-prev"
                className="c-arrow slick-arrow"
                onClick={() => sliderRef.current.slickPrev()}
              >
                <div className="c-arrow_embed w-embed">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.33317 8.13338L12.6665 8.13338"
                      stroke="currentColor"
                      strokeLinecap="square"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M6.51342 12.5535L6.86773 12.9063L7.57332 12.1976L7.219 11.8449L6.51342 12.5535ZM2.83288 8.18317L2.48006 7.82889L2.12427 8.1832L2.48009 8.53749L2.83288 8.18317ZM7.21903 4.52079L7.57332 4.16797L6.86767 3.4594L6.51339 3.81222L7.21903 4.52079ZM7.219 11.8449L3.18567 7.82886L2.48009 8.53749L6.51342 12.5535L7.219 11.8449ZM3.1857 8.53746L7.21903 4.52079L6.51339 3.81222L2.48006 7.82889L3.1857 8.53746Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
              </div>
              <div
                id="arrow-next"
                className="c-arrow cc-right slick-arrow"
                onClick={() => sliderRef.current.slickNext()}
              >
                <div className="c-arrow_embed w-embed">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.33317 8.13338L12.6665 8.13338"
                      stroke="currentColor"
                      strokeLinecap="square"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M6.51342 12.5535L6.86773 12.9063L7.57332 12.1976L7.219 11.8449L6.51342 12.5535ZM2.83288 8.18317L2.48006 7.82889L2.12427 8.1832L2.48009 8.53749L2.83288 8.18317ZM7.21903 4.52079L7.57332 4.16797L6.86767 3.4594L6.51339 3.81222L7.21903 4.52079ZM7.219 11.8449L3.18567 7.82886L2.48009 8.53749L6.51342 12.5535L7.219 11.8449ZM3.1857 8.53746L7.21903 4.52079L6.51339 3.81222L2.48006 7.82889L3.1857 8.53746Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="team-desc-wrapper">
          <h3>Apes In Space</h3>
          <p>
            The Apes in Space team has years of experience in the marketing and
            digital space under its belt. With Takeoff (1/3 of Migos) as part of
            the team, the Apes in Space project has more connections in the NFT
            space than any other collection.
          </p>
          <p>
            We spent months crafting the art of the project and we couldn’t be
            happier with the final result. Our entire team is beyond proud of
            everything we have accomplished so far, but this is only the
            beginning.
          </p>
        </div>
      </div>
    </Container>
  );
}
