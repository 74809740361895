import * as React from "react";
import Container from "@mui/material/Container";

const Terms = () => {
  return (
    <Container className="container">
      <div>
        <h2>TERMS</h2>
        <p>
          Apes In Space is a collection of digital artworks running on the
          Ethereum network via non fungible tokens(NFTs). apesinspace.io is only
          a visual interface to allow users to acquire the digital artworks.
          Users are responsible for the safety and management of their own
          Ethereum wallet and validating all transactions and contracts related
          and generated by apesinspace.io before approval. Also be aware that,
          since our Apes In Space smart contract runs on the Ethereum network,
          it is not possible to undo, reverse or restore any transactions.
          apesinspace.io and its connected services are provided “as is” and “as
          available” without warranty of any kind. By using apesinspace.io you
          are accepting sole responsibility for any and all transactions
          involving Apes In Space digital artworks.
        </p>
        <p>
          Depending on the popularity, scope and sales of the Apes in Space NFT
          project, we reserve the right to not purchase a land in the Metaverse
          or to purchase a smaller, less expensive land. We have no share in the
          Metaverse and no control over it. Therefore, we cannot guarantee the
          user experience and users will have to abide by the terms and
          conditions of the Metaverse. For this same reason, among others, we
          cannot guarantee that some Apes in Space holders will own the land or
          that we will give part of the land to the holders. At the moment, the
          land would be in Sandbox Metaverse, but this could change depending on
          several factors, including the popularity of projects offering an
          immersive experience in the Metaverse.
        </p>
        <p>
          This project is strictly an NFT art project that could eventually
          provide utilities for its holders. However, we do not guarantee any
          profitability for the buyers and it is understood that this is not the
          goal or the reason of the project. This project should not be
          considered as an investment
        </p>
        <p>
          As part of this project, we offer, in good faith, several giveaways.
          Depending on the popularity, scope and sales of the Apes in Space NFT
          project, we reserve the right to reduce or cancel these giveaways.
          Also, it is understood that some giveaways will only be made if the
          collection sells out.
        </p>
        <p>
          For all our giveaways with a physical prize such as the 4 VIP tickets
          to a Migos concert and the space trip with Takeoff, we reserve the
          right to pay the equivalent of the prize in ETH instead of giving the
          physical prize. In total, the price of 4 VIP tickets in USD is $4,000,
          or $1,000 per ticket and the price of the space trip ticket in USD is
          $250,000.
        </p>
        <p>
          The winner of the space trip with Takeoff or $250,000 USD will be the
          owner of the special 1/1 “Takeoff” Apes in Space at reveal.
        </p>
        <h2>OWNERSHIP</h2>
        <p>
          i. The user that is buying and minting our Apes In Space artwork is
          the owner of the Non Fungible Token (NFT) crated by validating the
          transaction. Each Apes In Space is an NFT on the Ethereum blockchain.
          When you purchase an NFT, you own all the rights to the underlying
          Apes In Space. Ownership of the NFT is mediated entirely and only by
          the Smart Contract and the Ethereum Network which means that, at no
          point, may we seize, freeze, or otherwise modify the ownership of any
          Apes In Space. ii. Personal Use. Subject to your continued compliance
          with these Terms, apesinspace.io grants you a worldwide, royalty-free
          license to use, copy, and display the purchased Apes In Space(s),
          along with any extensions that you choose to create or use, solely for
          the following purposes: (i) for your own personal, non-commercial use;
          (ii) as part of a marketplace that permits the purchase and sale of
          your Apes In Space/NFT, provided that the marketplace
          cryptographically verifies each Apes In Space owner’s rights to
          display the Apes In Space and ensures that only the actual owner can
          display the Apes In Space; or (iii) as part of a third party website
          or application that permits the inclusion, involvement, or
          participation of your Apes In Space, provided that the
          website/application cryptographically verifies each Apes In Space
          owner’s rights to display the Apes In Space and ensures that only the
          actual owner can display the Apes In Space, and provided that the Apes
          In Space is no longer visible once the owner of the Apes In Space
          leaves the website/application. iii. Commercial Use. Subject to your
          continued compliance with these Terms, apesinspace.io grants you an
          unlimited, worldwide license to use, copy, and display the purchased
          Apes In Space for the purpose of creating derivative works based upon
          the Apes In Space (“Commercial Use”). Examples of such Commercial Use
          would be the use of the Apes In Space to produce and sell merchandise
          products (T-Shirts etc.) displaying copies of the Apes In Space. For
          the sake of clarity, nothing in this Section will be deemed to
          restrict you from (i) owning or operating a marketplace that permits
          the use and sale of Apes In Space generally, provided that the
          marketplace cryptographically verifies each Apes In Space owner’s
          rights to display the Apes In Space and ensures that only the actual
          owner can display the Apes In Space; (ii) owning or operating a third
          party website or application that permits the inclusion, involvement,
          or participation of Apes In Space generally, provided that the third
          party website or application cryptographically verifies each Apes In
          Space owner’s rights to display the Apes In Space and ensures that
          only the actual owner can display the Apes In Space, and provided that
          the Apes In Space is no longer visible once the owner of the Purchased
          Apes In Space leaves the website/application; or (iii) earning revenue
          from any of the foregoing.
        </p>
      </div>
      <p>
        To enter our giveaways, you can also send an email to
        influencers@apesinspace.io stating the giveaway that you want to enter
        and your Discord id.
      </p>
    </Container>
  );
};

export default Terms;
