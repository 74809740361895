import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Container from "@mui/material/Container";

export default function SimpleAccordion() {
  return (
    <Container className="container" data-aos="zoom-out-up" id="faq">
      <div>
        <h2>FAQ'S</h2>
      </div>

      <Accordion className="accordion">
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              style={{
                color: "white",
              }}
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="accordion-title">
            HOW MUCH WILL IT COST TO MINT A SPACE APE?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="faq-desc">
            The Pre-Sale will take place on Sunday, December 5th, starting at 4
            PM EST, and the Public Sale taking place on Monday, December 6th,
            starting at 9 PM EST. Pre-Sale: 0.12 ETH + gas fees Public Sale: 0.3
            ETH + gas fees
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="accordion border-top">
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              style={{
                color: "white",
              }}
            />
          }
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className="accordion-title">
            WHERE WILL I BE ABLE TO MINT?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="faq-desc">
            The minting event for the pre-sale and the public launch will take
            place on our one and only website, apesinspace.io . You will be able
            to mint using ETH and a MetaMask wallet. Once minting is completed,
            you will be able to view your Apes In Space in your MetaMask wallet
            and on OpenSea.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="accordion border-top">
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              style={{
                color: "white",
              }}
            />
          }
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className="accordion-title">
            WHEN IS THE PRESALE?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="faq-desc">
            The presale will take place on December 5th, 2021. There will be
            2,000 spots in the presale, and all the whitelisted members will be
            guaranteed of minting one Ape in Space at a discounted price.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="accordion border-top">
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              style={{
                color: "white",
              }}
            />
          }
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className="accordion-title">
            WHEN IS THE PUBLIC SALE?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="faq-desc">
            The public sale will take place on December 6th, 2021, at 9 PM EST,
            directly on our website, apesinspace.io.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}
