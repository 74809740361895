import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Video from "../components/Video";

const HowToMint = (props) => {
  return (
    <Container className="verify-container">
      <div>
        <h2>How to Mint</h2>
        <Video />
      </div>
    </Container>
  );
};

export default HowToMint;
