import img from "../assets/avatars.png";
import bg from "../assets/1006.png";
// import bg from "../assets/1006.png";

const Section = () => {
  return (
    <div className="section2" style={{ background: `url(${bg})` }}>
      <div className="section gradient reverse">
        <div
          className="section-container"
          data-aos="fade-up"
          data-aos-offset="250"
        >
          <div className="section-text">
            <h3 className="roadmap-number">3.</h3>

            <div className="subtitle">Metaverse</div>
            <h2 className="cta-title section-tile">Assets</h2>
            <p className="cta-text">
              The Apes in Space project will create avatars and items to be
              integrated in the Metaverse. We aim to become a top IP in the
              Metaverse, and we plan to have multiple integrations in this space
              over the course of the next few months.
            </p>
            <div className="discord-btn-container">
              <a
                className="discord-btn"
                onClick={() =>
                  window.open("https://www.sandbox.game/en/shop/", "_blank")
                }
              >
                NFT Marketplace
              </a>
            </div>
          </div>
        </div>
        <div
          className="section-container section-container-img"
          data-aos="fade-up"
          data-aos-offset="250"
        >
          <img src={img} />
        </div>
      </div>
    </div>
  );
};

export default Section;
