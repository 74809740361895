import React, { useState, useEffect } from "react";
// import background from "../assets/bg.jpg";
import Container from "@mui/material/Container";
import background from "../assets/bg.png";
import ape from "../assets/ape.png";

const CTA = () => {
  //   const [offsetY, setOffsetY] = useState(0);
  //   const handleScroll = () => setOffsetY(window.pageYOffset);

  //   useEffect(() => {
  //     window.addEventListener("scroll", handleScroll);
  //     return () => window.removeEventListener("scroll", handleScroll);
  //   }, []);

  return (
    <div
      style={{ backgroundImage: `url(${background})` }}
      className="cta-background"
    >
      <div id="cta">
        <Container className="container">
          <div className="cta-wrapper">
            <div>
              <h2 className="cta-title">Join the community</h2>
              <p className="cta-text">
                Apes in Space aims to become the fastest-growing community in
                the crypto space, with every connection in the NFT environment
                promoting the project. Join our Discord today to reserve your
                seat on your trip to the Moon!
              </p>
              <div className="discord-btn-container">
                <a
                  className="discord-btn"
                  onClick={() =>
                    window.open(
                      "https://discord.com/invite/apesinspace",
                      "_blank"
                    )
                  }
                >
                  Join Discord
                </a>
              </div>
            </div>

            <img src={ape} className="cta-img" />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default CTA;
