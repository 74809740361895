import React, { useState, useEffect } from "react";
// import background from "../assets/bg.jpg";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import background from "../assets/bg.png";
import ape from "../assets/ape.png";

const About = () => {
  return (
    <Container className="container" id="about">
      <h2 data-aos="zoom-out-up">Space</h2>
      <h3 data-aos="zoom-out-up">More than just a destination</h3>
      <p className="about-text" data-aos="zoom-out-up">
        The year is 2203. Oil reserves on Earth are low, and new wars are fought
        every day to gain control over those scarce resources. In order to
        maximize their fighting power, armies have started training apes for
        combat using the most advanced technology. We now live in an era where
        apes have a consciousness, but are confined to the battlefield.
      </p>
      <p data-aos="zoom-out-up" style={{ marginBottom: "50px" }}>
        While on a mission in Qatar to take control over a number of oil wells,
        a team of apes planned an escape from combat slavery, which would
        ultimately lead to their freedom. Their plan? Use the last oil resources
        on Earth to fuel a spaceship and bring all of their friends in orbit.
      </p>
      {/* <h3 data-aos="zoom-out-up">New in the NFT Space?</h3>
      <p data-aos="zoom-out-up">
        While on a mission in Qatar to take control over a number of oil wells,
        a team of apes planned an escape from combat slavery, which would
        ultimately lead to their freedom. Their plan? Use the last oil resources
        on Earth to fuel a spaceship and bring all of their friends in orbit.
      </p> */}
      <div
        className="discord-btn-container"
        style={{ textAlign: "center" }}
        data-aos="zoom-out-up"
      >
        <a
          className="discord-btn"
          onClick={() => window.location.replace("/how-to-mint")}
        >
          How to mint
        </a>
      </div>
    </Container>
  );
};

export default About;
