import img from "../assets/land.png";
import bg from "../assets/1004.png";

const Section = () => {
  return (
    <div style={{ background: `url(${bg})` }}>
      <div className="section gradient">
        <div
          className="section-container section-container-img"
          // style={{ backgroundImage: `url(${img})` }}
          data-aos="fade-up"
          data-aos-offset="250"
        >
          <img src={img} />
        </div>
        <div className="section-container">
          <div
            className="section-text"
            data-aos="fade-up"
            data-aos-offset="250"
          >
            <h3 className="roadmap-number">2.</h3>
            <div>
              <div className="subtitle">Metaverse</div>
              <h2 className="cta-title section-tile">Land ownership</h2>
            </div>
            <p className="cta-text" style={{ marginBottom: "20px" }}>
              Upon sell-out, we will acquire a piece of land in the Metaverse in
              Sandbox. We will then be able to organize the first Hip-Hop
              festival in the Metaverse. Lucky Apes in Space holders will get to
              own 69% of 1 full square of land.
            </p>
            <p className="cta-text">
              The remaining 31% will be owned by the rest of the community.
              Therefore, by owning a Space Ape, you will also own a portion of
              land in the Metaverse
            </p>
            <div className="discord-btn-container">
              <a
                className="discord-btn"
                onClick={() =>
                  window.open("https://www.sandbox.game/", "_blank")
                }
              >
                About the metaverse
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section;
